import * as React from 'react';
import loadable from '@loadable/component'
import { Redirect, Route, Switch } from 'react-router';
import Layout from './components/Layout';
const Landing = loadable(() => import('./pages/Landing'))
const Home = loadable(() => import('./pages/Home'))
const Transaction = loadable(() => import('./pages/transaction'))
const Invoice = loadable(() => import('./pages/invoice'))
const InvoiceDetail = loadable(() => import('./pages/invoice/detail'))
const DreamBook = loadable(() => import('./pages/dream'))
const BetDetail = loadable(() => import('./pages/BetDetail'));
const MarketResultHistory = loadable(() => import('./pages/MarketResultHistory'));
// const Auth = loadable(() => import('./pages/Auth'))
// import Home from "./pages/Home";
// import Play from "./pages/Play";
// import Transaction from "./pages/Transaction";
// import Auth from "./pages/Auth";
import AuthStore from './store/Auth';

import './assets/main.css'
import ProtectedRoute from './components/ProtectedRoute';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './assets/bootstrap.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import PlayStore from './store/Play';
import { useEffect } from 'react';
import { useState } from 'react';
import TestBottom from './pages/TestBottom';
import TestBottomGrid from './pages/TestBottomGrid';
import TestCss from './pages/TestCss';
library.add(fab, fas);

export default connect(
  (state) => ({ auth: state.auth, play: state.play }),
  { ...AuthStore.actionCreators, ...PlayStore.actionCreators }
)(({ auth, play, ...props }) => {

  useEffect(() => {
    if (auth.needRefresh && !auth.isLoading) {
      props.refresh()
    }
  }, [auth.needRefresh])

  useEffect(() => {
    if (auth.isAuthenticated) {
      props.requestPlayVersion()
      props.requestBalance()
    }
  }, [auth.isAuthenticated])

  const [Play, setPlay] = useState(Landing)

  useEffect(() => {
    if (play.version) {
      setPlay(loadable(() => import(`./pages/games/${play.version}/Play`)))
    }
  }, [play.version])

  return (
    <Layout>
      <Switch>
        {/* <Route path='/login' component={Login} /> */}
        {/* <Route path='/play/auth' component={Auth} /> */}
        <Route path='/bet/*' component={BetDetail} />
        <Route exact path='/test/bottom' component={TestBottom} />
        <Route exact path='/test/bottom-grid' component={TestBottomGrid} />
        <Route exact path='/test/css' component={TestCss} />
        {auth.isAuthenticated && play.version && (
          <>
            <Route exact path='/'>
              <Redirect to="/play"></Redirect>
            </Route>
            {/* <Route path='/logout' component={Logout} /> */}
            <Route path='/play/:market?' component={Play} />
            <Route path='/transaction' component={Transaction} />
            <Route exact path='/invoice' component={Invoice} />
            <Route path='/invoice/*' component={InvoiceDetail} />
            <Route path='/bukumimpi' component={DreamBook} />
            <Route path='/results/:market?' component={MarketResultHistory} />
          </>
        )
          || <Route exact path='/' component={Landing} />}
        {!auth.isAuthenticated && !auth.isLoading && play.version
          && <Redirect to='/'></Redirect>
        }
        <Route path='*' component={Landing} />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable />
    </Layout>
  );
})