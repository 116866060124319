
export function useSetter(method, setter) {
    return (newValue) => setter(value => {
        method(setter, newValue);
        return value;
    })
}
export const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export const prepareCssVar100vh = () => {

    document.documentElement.style.setProperty(
        "--100vh",
        `${window.innerHeight}px`
    );

    window.addEventListener(
        "resize",
        debounce(() => {
            document.documentElement.style.setProperty(
                "--100vh",
                `${window.innerHeight}px`
            );
        }, 100)
    );

}