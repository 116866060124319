import React, { useMemo, useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux';
import { Col, Row, Label, InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import NumberFormat from 'react-number-format';

const isClient = typeof window != 'undefined';
const TestBottom = function ({ ...props }) {

    const [isOverlayContent, setIsOverlayContent] = useState(isClient && navigator.navigator?.virtualKeyboard?.overlaysContent || false)
    const [bottomBarPosition, setBottomBarPosition] = useState('fixed')
    const testKeyboardInput = useRef()

    const isVirtualKeyboardSupported = useMemo(() => isClient && 'virtualKeyboard' in navigator, [isClient])
    const isVisualViewportSupported = useMemo(() => isClient && 'visualViewport' in window, [isClient])

    useEffect(() => {
        if (typeof window != 'undefined' && window.navigator?.virtualKeyboard) {
            const prev = window.navigator.virtualKeyboard.overlaysContent;
            window.navigator.virtualKeyboard.overlaysContent = false; // ONLY WORKS USING CHROME??

            return () => {
                window.navigator.virtualKeyboard.overlaysContent = prev; // ONLY WORKS USING CHROME??
            }
        }
    }, [])

    return (
        <div className="flex-grow-1 overflow-auto d-flex flex-column">
            <div className="flex-grow-1 d-flex flex-column overflow-auto mh-100 h-100">
                <fieldset className="flex-grow-1 d-flex flex-column overflow-auto mh-100 h-100">
                    <div>
                        Virtual keyboard is {isVirtualKeyboardSupported && <span className="text-success font-weight-bold">Supported</span> || <span className="text-danger font-weight-bold">Not Supported</span>}
                    </div>
                    <div>
                        Visual Viewport API is {isVisualViewportSupported && <span className="text-success font-weight-bold">Supported</span> || <span className="text-danger font-weight-bold">Not Supported</span>}
                        {isClient && isVisualViewportSupported
                            && <Row>
                                <code className="d-block col" style={{ whiteSpace: 'pre-line' }}>
                                    {`LAYOUT VIEWPORT:
                                Height: ${document.body?.clientHeight?.toFixed(2)}
                                Width: ${document.body?.clientWidth?.toFixed(2)}`}
                                </code>
                                <code className="d-block col" style={{ whiteSpace: 'pre-line' }}>
                                    {`VISUAL VIEWPORT:
                                    Height: ${visualViewport.height.toFixed(2)}
                                    Width: ${visualViewport.width.toFixed(2)}
                                    Scale: ${visualViewport.scale}
                                    PageTop: ${visualViewport.pageTop}`}
                                </code>
                            </Row>
                        }
                    </div>
                    <input ref={testKeyboardInput} inputMode="numeric" maxLength={1}></input>
                    {isVirtualKeyboardSupported
                        && <Label>
                            <input type="checkbox" checked={isOverlayContent} onChange={event => {
                                setIsOverlayContent(event.target.checked)
                                navigator.virtualKeyboard.overlaysContent = event.target.checked
                                testKeyboardInput.current.focus()
                            }} /> navigator.virtualKeyboard.overlaysContent
                        </Label>
                    }
                    {!isOverlayContent
                        && <select className="custom-select" onChange={(event) => {
                            const metaViewport = document.querySelector('meta[name="viewport"');
                            metaViewport.content = metaViewport.content.replace(/(, )?interactive-widget=[\w-]*/, '') + ', interactive-widget=' + event.target.value
                            testKeyboardInput.current.focus()
                        }}>
                            <option value="resizes-visual">resizes-visual</option>
                            <option value="resizes-content">resizes-content</option>
                            <option value="overlays-content">overlays-content</option>
                        </select>
                    }
                    <select className="custom-select" onChange={(event) => {
                        setBottomBarPosition(event.target.value)
                        testKeyboardInput.current.focus()
                    }}>
                        <option value="fixed">fixed</option>
                        <option value="sticky">sticky</option>
                        <option value="relative">relative</option>
                        <option value="absolute">absolute</option>
                    </select>
                </fieldset>
            </div>
            <Row className="justify-content-end py-2 flex-column w-100 bg-dark" style={{ position: bottomBarPosition, bottom: 'env(keyboard-inset-height, 0px)', zIndex: 100 }}>
                <Col xs className="mb-1">
                    <Row noGutters>
                        <Col className="mr-1">
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">Bayar: </InputGroupAddon>
                                <NumberFormat customInput={Input} autoComplete="off" aria-autocomplete="off" value={0} thousandSeparator={true} readOnly
                                    decimalScale={2} className="text-right text-gold font-weight-bold" />
                            </InputGroup>
                        </Col>
                        <Col xs="auto">
                            <Button color="gradient-primary" className="border-gold text-gold">Simpan</Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs className=" text-center">
                    {/* <h4 className="font-weight-bold text-gold">Rangkuman Taruhan</h4> */}
                    <h4 className="text-gold">
                        <span className="text-gold weight-bold">{0}</span> nomor,
                        bayar <NumberFormat displayType="text" value={0} thousandSeparator={true} readOnly
                            decimalScale={2} className="text-gold weight-bold" />
                    </h4>
                </Col>
                <Col xs className="">
                    <Button color="gradient-gold" size="lg" block className="font-weight-bold">
                        Pasang
                        {/* {readyTotals.count && <Badge color="success" className="ml-1">{readyTotals.count}</Badge> || ''} */}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default connect(
    (state) => ({
    }), // Selects which state properties are merged into the component's props
    {}
)(TestBottom);
