import AuthStore from './Auth';
import GameStore from './Games';
import PlayStore from './Play';
import TransactionStore from './Transaction';

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    games: GameStore.reducer,
    auth: AuthStore.reducer,
    play: PlayStore.reducer,
    transaction: TransactionStore.reducer
};