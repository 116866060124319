import Axios from 'axios';
import { createElement } from 'react';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
import https from "https";

let cookie = new Cookies()
import { runtimeConfig } from './config';

const baseURL = runtimeConfig.apiUrl;

// console.log(process.env.NODE_ENV, process.env.RAZZLE_API_URL, runtimeConfig, baseURL)

const http = process.env.NODE_ENV == 'development' ? Axios.create({
    baseURL,
    httpsAgent: new https.Agent({
        rejectUnauthorized: false
    })
}) : Axios.create({
    baseURL,
})

// http.interceptors.request.use(config => {
//     if (cookie.get('accessToken')) {
//         config.headers = {
//             Authorization: `Bearer ${cookie.get('accessToken')}`,
//             ...config.headers
//         }
//     }
//     return config;
// })
var noopPromise = {
    then: () => noopPromise,
    catch: () => noopPromise
}
function haltPromiseChain(promise) {
    promise.catch(noopPromise)

    return noopPromise
}
const globalErrorHandler = (error) => {
    if (error.response) {
        const resp = error.response
        const { status, data } = resp;
        if (status == 400 && data && data.title && data.errors) {
            toast.error(createElement('div', {}, [
                createElement('h4', {}, [
                    data.title
                ]),
                ...Object.entries(data.errors).map(([key, errs]) => errs)
            ]))
        }
        else if (data?.message) {
            toast.error(resp.data.message, {
                autoClose: 5000
            })
        }
    }
    else {
        toast.error('Invalid token')
    }
}

// http.interceptors.response.use(null, globalErrorHandler)

http.interceptors.response.use(null, error => {
    return new Promise((resolve, reject) => {
        if (error.config?.errorHandler) {
            return error.config.errorHandler(error)
        }

        if (error) {
            var resp = error.response;
            if (resp) {
                if (resp.status == 400 && resp.data && resp.data.title && resp.data.errors) {
                    toast.error(createElement('div', {}, [
                        createElement('h4', {}, [
                            resp.data.title
                        ]),
                        ...Object.entries(resp.data.errors).map(([key, errs]) => errs)
                    ]))
                }
                else if (resp.data && resp.data.message) {
                    toast.error(resp.data.message, {
                        autoClose: 5000
                    })
                }
            } else {
                // console.error(error.message);
                // toast.error(error.message, {
                //     autoClose: 10000
                // })
            }
        }

        return reject(error)
    })
})

export default http;