import React, { useMemo, useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux';
import { Col, Row, Label, InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import NumberFormat from 'react-number-format';

const isClient = typeof window != 'undefined';
const isVirtualKeyboardSupported = isClient && 'virtualKeyboard' in navigator

const TestCss = function ({ ...props }) {

    const [isOverlayContent, setIsOverlayContent] = useState(isClient && navigator.navigator?.virtualKeyboard?.overlaysContent || false)
    const [bottomBarPosition, setBottomBarPosition] = useState('fixed')
    const testKeyboardInput = useRef()

    const isVirtualKeyboardSupported = useMemo(() => isClient && 'virtualKeyboard' in navigator, [isClient])
    const isVisualViewportSupported = useMemo(() => isClient && 'visualViewport' in window, [isClient])

    useEffect(() => {
        if (typeof window != 'undefined' && window.navigator?.virtualKeyboard) {
            const prev = window.navigator.virtualKeyboard.overlaysContent;
            window.navigator.virtualKeyboard.overlaysContent = false; // ONLY WORKS USING CHROME??

            return () => {
                window.navigator.virtualKeyboard.overlaysContent = prev; // ONLY WORKS USING CHROME??
            }
        }
    }, [])

    return (
        <div className="flex-grow-1 overflow-auto" style={{ display: 'grid', gridTemplate: '1fr 120px / 1fr', rowGap: 5 }}>
            <div className="flex-grow-1 d-flex flex-column overflow-auto mh-100 h-100">
                <fieldset className="flex-grow-1 d-flex flex-column overflow-auto mh-100 h-100">
                    <div>
                        <Row>
                            <code className="d-block col" style={{ whiteSpace: 'pre-line' }}>
                                {`window:
                                Height: ${isClient && window.innerHeight?.toFixed(2)}
                                Width: ${isClient && window.innerWidth?.toFixed(2)}`}
                            </code>
                            <code className="d-block col" style={{ whiteSpace: 'pre-line' }}>
                                {`document:
                                Height: ${isClient && document.documentElement.clientHeight?.toFixed(2)}
                                Width: ${isClient && document.documentElement.clientWidth?.toFixed(2)}`}
                            </code>
                        </Row>
                    </div>
                    <input ref={testKeyboardInput} inputMode="numeric" maxLength={1}></input>
                    <select className="custom-select" onChange={(event) => {
                        setBottomBarPosition(event.target.value)
                        testKeyboardInput.current.focus()
                    }}>
                        <option value="fixed">fixed</option>
                        <option value="sticky">sticky</option>
                        <option value="relative">relative</option>
                        <option value="absolute">absolute</option>
                    </select>
                </fieldset>
            </div>
            <Row className={"justify-content-end py-2 flex-column bg-dark" + (bottomBarPosition == 'absolute' || bottomBarPosition == 'fixed' ? ' w-100' : '')} style={{ position: bottomBarPosition, bottom: 'env(keyboard-inset-height, 0px)', zIndex: 100 }}>
                <Col xs className="mb-1">
                    <Row noGutters>
                        <Col className="mr-1">
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">Bayar: </InputGroupAddon>
                                <NumberFormat customInput={Input} autoComplete="off" aria-autocomplete="off" value={0} thousandSeparator={true} readOnly
                                    decimalScale={2} className="text-right text-gold font-weight-bold" />
                            </InputGroup>
                        </Col>
                        <Col xs="auto">
                            <Button color="gradient-primary" className="border-gold text-gold">Simpan</Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs className=" text-center">
                    {/* <h4 className="font-weight-bold text-gold">Rangkuman Taruhan</h4> */}
                    <h4 className="text-gold">
                        <span className="text-gold weight-bold">{0}</span> nomor,
                        bayar <NumberFormat displayType="text" value={0} thousandSeparator={true} readOnly
                            decimalScale={2} className="text-gold weight-bold" />
                    </h4>
                </Col>
                <Col xs className="">
                    <Button color="gradient-gold" size="lg" block className="font-weight-bold">
                        Pasang
                        {/* {readyTotals.count && <Badge color="success" className="ml-1">{readyTotals.count}</Badge> || ''} */}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default connect(
    (state) => ({
    }), // Selects which state properties are merged into the component's props
    {}
)(TestCss);
