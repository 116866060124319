import http from "../http";
import jwtDecode from "jwt-decode";
import { Cookies } from "react-cookie";

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    refresh: () => (dispatch) => {
        const token = cookies.get('refreshToken')
        if (token) {
            const promise = http.post('play/auth/refresh', { refreshToken: token }, {
                errorHandler: error => {
                    var resp = error.response;
                    if (resp) {
                        if (resp.status === 401) {
                            dispatch({ type: 'REFRESH_EXPIRED', message: 'Sesi Anda berakhir. Mohon login ulang' })
                        }
                        else {
                            dispatch({ type: 'REFRESH_FAILED', message: resp.data.message || 'Invalid token' })
                        }
                    }

                    return Promise.resolve(error)
                }
            })
            dispatch({ type: 'REFRESHING', promise })

            return promise.then(resp => {
                if (resp.data.accessToken) {
                    const { accessToken, accessTokenExpDate, refreshToken, refreshTokenExpDate } = resp.data;
                    const jwt = jwtDecode(accessToken);
                    if (jwt) {
                        dispatch({ type: 'REFRESH_SUCCEED', accessToken, accessTokenExpDate, refreshToken, refreshTokenExpDate, jwt });
                    } else {
                        dispatch({ type: 'REFRESH_FAILED', message: 'Invalid token' })
                    }
                }
            })
        }
        else {
            dispatch({ type: 'REFRESH_EXPIRED', message: 'Sesi Anda berakhir. Mohon login ulang' })
            return Promise.reject()
        }
    },
    logout: () => (dispatch) => {
        const refreshToken = cookies.get('refreshToken')
        const promise = http.post('auth/logout', { refreshToken }).then(resp => {
            console.log('Logout')
            dispatch({ type: 'LOGOUT_SUCCEED' })
        })
        dispatch({ type: 'LOGGING_OUT', promise });
        return promise;
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState = {
    isLoading: false,
    isAuthenticated: false,
    isReady: false,
    isSessionExpired: false,
    user: null,
    role: null,
    jwt: null,
    agent: null,
    needRefresh: false,
    promise: Promise.resolve()
};

var cookies = new Cookies();
if (cookies.get('accessToken')) {
    const jwt = jwtDecode(cookies.get('accessToken'));

    if (jwt) {
        unloadedState.jwt = jwt;
        if (jwt.exp * 1000 > Date.now()) {
            unloadedState.user = {
                id: jwt.id,
                name: jwt.name,
                role: jwt.role,
            };
            unloadedState.role = jwt.role
            unloadedState.isAuthenticated = true

            http.defaults.headers.common['Authorization'] = `Bearer ${cookies.get('accessToken')}`;
            unloadedState.isReady = true
        } else {
            unloadedState.isAuthenticated = true
            unloadedState.needRefresh = true
            unloadedState.isSessionExpired = true
        }

        unloadedState.agent = {
            id: jwt.agentId,
            name: jwt.agentName,
            url: jwt.agentUrl
        }

    }
}
else if (cookies.get('refreshToken')) {
    unloadedState.needRefresh = true;
}

export const reducer = (state, incomingAction) => {
    // console.log(state, unloadedState)
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction;
    switch (action.type) {
        case 'AUTHENTICATION_SUCCEED':
        case 'REFRESH_SUCCEED':
            cookies.set('accessToken', action.accessToken, {
                path: '/',
                expires: new Date(action.accessTokenExpDate)
            });
            cookies.set('refreshToken', action.refreshToken, {
                path: '/',
                expires: new Date(action.refreshTokenExpDate)
            });

            http.defaults.headers.common['Authorization'] = `Bearer ${cookies.get('accessToken')}`;
            const jwt = action.jwt;
            return {
                ...state,
                jwt: jwt,
                user: {
                    username: jwt.username,
                    name: jwt.name,
                    role: jwt.role
                },
                role: jwt.role,
                isAuthenticated: true,
                isLoading: false,
                isTokenExpired: false,
                needRefresh: false,
                promise: Promise.resolve(action.type)
            };
        case 'AUTHENTICATION_FAILED':
        case 'REFRESH_FAILED':
            cookies.remove('accessToken')
            delete http.defaults.headers.common['Authorization'];

            return {
                ...state,
                jwt: null,
                user: null,
                role: null,
                isAuthenticated: false,
                isLoading: false,
                needRefresh: false,
                promise: Promise.reject(action.type)
            };
        case 'REFRESH_EXPIRED':
            cookies.remove('accessToken')
            cookies.remove('refreshToken')
            delete http.defaults.headers.common['Authorization'];

            return {
                ...state,
                jwt: null,
                user: null,
                role: null,
                isAuthenticated: false,
                isTokenExpired: true,
                isLoading: false,
                needRefresh: false,
                promise: Promise.reject(action.type)
            };
        case 'AUTHENTICATING':
        case 'REFRESHING':
        case 'LOGGING_OUT':
            return {
                ...state,
                isLoading: true,
                promise: action.promise
            }
        case 'LOGOUT_SUCCEED':
            cookies.remove('accessToken')
            cookies.remove('refreshToken')
            delete http.defaults.headers.common['Authorization'];

            return {
                ...state,
                jwt: null,
                user: null,
                role: null,
                isAuthenticated: false,
                isLoading: false,
                needRefresh: false,
                promise: Promise.resolve(action.type)
            }
        default:

    }

    return state;
};

const AuthStore = { actionCreators, reducer }
export default AuthStore