import http from '../http';
import moment from 'moment';

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestMarkets: (force = false) => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (force || appState) {
            http.get(`play/markets`)
                .then(({ data }) => {
                    dispatch({ type: 'RECEIVE_MARKETS', markets: data });
                    dispatch(actionCreators.requestBalance())
                });

            dispatch({ type: 'REQUEST_MARKETS' });
        }
    },
    requestResultHistory: (marketId, page, pageSize, force = false) => (dispatch, getState) => {
        const appState = getState();
        if (force || appState) {
            http.get(`play/market/${marketId}/results`, {
                params: {
                    page,
                    pageSize
                }
            })
                .then(({ data: { data, total } }) => {
                    dispatch({ type: 'RECEIVE_RESULT_HISTORY', page, results: data, totalSize: total });
                });

            dispatch({ type: 'REQUEST_RESULT_HISTORY', page, pageSize });
        }
    },
    requestBalance: () => (dispatch, getState) => {
        dispatch({ type: 'REQUEST_BALANCE' });
        return http.get(`play/balance`)
            .then(({ data }) => {
                dispatch({ type: 'RECEIVE_BALANCE', balance: data.amount, currency: data.currency });
                return data
            })
    },
    placeBet: (bet) => (dispatch, getState) => {
        dispatch({ type: 'PLACING_BET' });
        return http.post(`play/bet`, bet)
            .then(() => {
                dispatch({ type: 'BET_PLACED' });
            })
            .catch((e) => {
                dispatch({ type: 'BET_FAILED' });
                console.error(e)
                throw e
            }).finally(() => {
                dispatch(actionCreators.requestBalance())
            })
    },
    // cancelBet: (id) => (dispatch, getState) => {
    //     dispatch({ type: 'CANCELLING_BET' });
    //     return http.post(`play/bet/cancel`, [id])
    //         .then(() => {
    //             dispatch({ type: 'BET_CANCELLED' });
    //             dispatch(actionCreators.requestBalance())
    //         })
    //         .catch((e) => {
    //             dispatch({ type: 'BET_CANCEL_FAILED' });
    //             throw e
    //         })
    // },
    requestBets: (from, to, status, page, pageSize, force = false) => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        status = status || ''
        const { play: { transaction: appState } } = getState();
        if (force || appState && (page !== appState.page || from !== appState.dateFrom || to !== appState.dateTo || status !== appState.status)) {
            http.get(`play/bets/${status}`, {
                params: {
                    page,
                    pageSize,
                    from,
                    to
                }
            })
                .then(({ data: { data, total } }) => {
                    dispatch({ type: 'RECEIVE_BETS', page, bets: data, totalSize: total });
                    dispatch(actionCreators.requestBalance())
                });

            dispatch({ type: 'REQUEST_BETS', dateFrom: from, dateTo: to, status, page, pageSize });
        }
    },
    reloadBets: () => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const { play: { transaction: { dateFrom: from, dateTo: to, status, page, pageSize } } } = getState();

        http.get(`play/bets/${status}`, {
            params: {
                page,
                pageSize,
                from,
                to
            }
        })
            .then(({ data: { data, total } }) => {
                dispatch({ type: 'RECEIVE_BETS', page, bets: data, totalSize: total });
                dispatch(actionCreators.requestBalance())
            });

        dispatch({ type: 'REQUEST_BETS', dateFrom: from, dateTo: to, status, page, pageSize });
    },
    requestInvoices: (from, to, page, pageSize, force = false) => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const { play: { transaction: appState } } = getState();
        if (force || appState && (page !== appState.page || from !== appState.dateFrom || to !== appState.dateTo)) {
            http.get(`play/invoices`, {
                params: {
                    page,
                    pageSize,
                    from,
                    to
                }
            })
                .then(({ data: { data, total } }) => {
                    dispatch({ type: 'RECEIVE_INVOICES', page, invoices: data, totalSize: total });
                    dispatch(actionCreators.requestBalance())
                });

            dispatch({ type: 'REQUEST_INVOICES', dateFrom: from, dateTo: to, page, pageSize });
        }
    },
    reloadInvoices: () => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const { play: { transaction: { dateFrom: from, dateTo: to, page, pageSize } } } = getState();

        http.get(`play/invoices`, {
            params: {
                page,
                pageSize,
                from,
                to
            }
        })
            .then(({ data: { data, total } }) => {
                dispatch({ type: 'RECEIVE_INVOICES', page, invoices: data, totalSize: total });
                dispatch(actionCreators.requestBalance())
            });

        dispatch({ type: 'REQUEST_INVOICES', dateFrom: from, dateTo: to, page, pageSize });
    },
    requestInvoice: (invoiceNo) => (dispatch, getState) => {
        return http.get(`play/invoice/${invoiceNo}`)
            .then(({ data }) => data);
    },
    requestBet: (betId) => (dispatch, getState) => {
        return http.get(`play/bet/${betId}`)
            .then(({ data }) => data);
    },
    requestDreamBooks: (force = false) => (dispatch, getState) => {
        if (localStorage.dreamBooks) {
            return Promise.resolve(JSON.parse(localStorage.dreamBooks));
        }

        return http.get(`dreambooks`)
            .then(({ data }) => {
                localStorage.dreamBooks = JSON.stringify(data);
                return data;
            });
    },
    requestPlayVersion: (force = false) => (dispatch, getState) => {
        const appState = getState()
        if (force || (appState && !appState.version)) {
            http.get(`play/version`)
                .then(({ data }) => {
                    dispatch({
                        type: 'RECEIVE_PLAY_VERSION', version: data
                    });
                });

            dispatch({ type: 'REQUEST_PLAY_VERSION' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState = {
    version: null,
    games: {},
    markets: [],
    balance: null,
    currency: null,
    resultHistory: {
        results: [],
        isLoading: false,
        page: 0,
        pageSize: 10,
        totalSize: 0
    },
    transaction: {
        dateFrom: moment().add(-7, 'd').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD'),
        status: '',
        bets: [],
        isLoading: false,
        page: 0,
        pageSize: 10,
        totalSize: 0
    },
    invoice: {
        dateFrom: moment().add(-7, 'd').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD'),
        invoices: [],
        isLoading: false,
        page: 0,
        pageSize: 10,
        totalSize: 0
    },
    isLoading: false
};

export const reducer = (state, incomingAction) => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction;
    switch (action.type) {
        case 'REQUEST_GAMES':
        case 'REQUEST_BALANCE':
        case 'PLACING_BET':
        case 'CANCELLING_BET':
        case 'REQUEST_PLAY_VERSION':
            return {
                ...state,
                isLoading: true
            };
        case 'RECEIVE_PLAY_VERSION':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            return {
                ...state,
                version: action.version,
                isLoading: false
            };
        case 'REQUEST_MARKETS':
            return {
                ...state,
                isLoading: true
            };
        case 'RECEIVE_GAMES':
            const games = Object.fromEntries(action.games.map(g => [g.code.toLowerCase(), {
                ...g,
                variants: Object.fromEntries(g.variants.map(v => [v.code.toLowerCase(), v]))
            }]))
            return {
                ...state,
                games: games,
                isLoading: false
            };
        case 'RECEIVE_MARKETS':
            return {
                ...state,
                markets: action.markets,
                isLoading: false
            };
        case 'RECEIVE_BALANCE':
            return {
                ...state,
                currency: action.currency,
                balance: action.balance,
                isLoading: false
            };
        case 'REQUEST_RESULT_HISTORY':
            return {
                ...state,
                resultHistory: {
                    ...state.resultHistory,
                    page: action.page,
                    pageSize: action.pageSize,
                    isLoading: true
                }
            };
        case 'RECEIVE_RESULT_HISTORY':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.page === state.resultHistory.page) {
                return {
                    ...state,
                    resultHistory: {
                        ...state.resultHistory,
                        page: action.page,
                        totalSize: action.totalSize,
                        results: action.results,
                        isLoading: false
                    }
                };
            }
            break;
        case 'REQUEST_BETS':
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    dateFrom: action.dateFrom,
                    dateTo: action.dateTo,
                    status: action.status,
                    page: action.page,
                    pageSize: action.pageSize,
                    isLoading: true
                }
            };
        case 'RECEIVE_BETS':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.page === state.transaction.page) {
                return {
                    ...state,
                    transaction: {
                        ...state.transaction,
                        page: action.page,
                        totalSize: action.totalSize,
                        bets: action.bets,
                        isLoading: false
                    }
                };
            }
            break;
        case 'BET_CANCEL_FAILED':
        case 'BET_CANCELLED':
        case 'BET_FAILED':
        case 'BET_PLACED':
            return {
                ...state,
                isLoading: false
            };
        case 'REQUEST_INVOICES':
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    dateFrom: action.dateFrom,
                    dateTo: action.dateTo,
                    page: action.page,
                    pageSize: action.pageSize,
                    isLoading: true
                }
            };
        case 'RECEIVE_INVOICES':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.page === state.invoice.page) {
                return {
                    ...state,
                    invoice: {
                        ...state.invoice,
                        page: action.page,
                        totalSize: action.totalSize,
                        invoices: action.invoices,
                        isLoading: false
                    }
                };
            }
            break;
    }

    return state;
};

const PlayStore = { actionCreators, reducer };
export default PlayStore;