import http from '../http';


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAllGames: () => {
        actionCreators.requestGames(1, 0)
    },
    checkVersion: () => (dispatch, getState) => {
        const appState = getState();
        http.get(`game/version`)
            .then(({ data: version }) => {
                if (appState && appState.games.version != version) {
                    dispatch(actionCreators.requestGames(true))
                }
                dispatch({ type: 'RECEIVE_VERSION', version });
                return version
            });
        dispatch({ type: 'REQUEST_VERSION' });
    },
    requestGames: (force = false) => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (force || appState && appState.games) {
            http.get(`game`)
                .then(({ data }) => {
                    dispatch({ type: 'RECEIVE_GAMES', games: data });
                    return data
                });

            dispatch({ type: 'REQUEST_GAMES' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState = { games: {}, gamePlayVersion: null, version: null, isLoading: false };

const _window = typeof window != 'undefined' ? window : null;
if (_window) {
    if (_window.localStorage) {
        const _localStorage = _window.localStorage
        if (_localStorage.gameVersion) {
            unloadedState.version = _localStorage.gameVersion;
        }
        if (_localStorage.games) {
            unloadedState.games = JSON.parse(_localStorage.games)

            for (const gameCode in unloadedState.games) {
                const g = unloadedState.games[gameCode]
                for (const variantCode in unloadedState.games[gameCode].variants) {
                    const v = unloadedState.games[gameCode].variants[variantCode]
                    v.inputRegex = new RegExp(v.inputFormat, 'i')
                    v.extractGuess = input => v.inputRegex && input.replace(v.inputRegex, v.guessExtract) || input
                    v.isInputValid = input => v.inputRegex.test(input)
                    v.formatGuess = guess => {
                        let formattedGuess = guess
                        if (v.inputDisplayFormat)
                            formattedGuess = v.inputDisplayFormat.replace('{input}', guess)
                        else if (g.options && Object.keys(g.options).length)
                            formattedGuess = g.options[guess] || guess.split(',').map(g2 => g.options[g2]).join('-')
                        return formattedGuess
                    }
                    
                }
            }
        }
    }
}

export const reducer = (state, incomingAction) => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction;
    switch (action.type) {
        case 'REQUEST_VERSION':
        case 'RECEIVE_VERSION':
            _window && (_window.localStorage.gameVersion = action.version)
            return {
                ...state,
                version: action.version,
                isLoading: false
            };
        case 'REQUEST_GAMES':
            return {
                ...state,
                isLoading: true
            };
        case 'RECEIVE_GAMES':
            const games = Object.fromEntries(action.games.map(g => [g.code.toLowerCase(), {
                ...g,
                variants: Object.fromEntries(g.variants.map(v => {
                    const inputRegex = new RegExp(v.inputFormat, 'i')
                    const extractGuess = input => inputRegex && input.replace(inputRegex, v.guessExtract) || input
                    const isInputValid = input => inputRegex.test(input)
                    const formatGuess = guess => {
                        let formattedGuess = guess
                        if (v.inputDisplayFormat)
                            formattedGuess = v.inputDisplayFormat.replace('{input}', guess)
                        else if (g.options && Object.keys(g.options).length)
                            formattedGuess = g.options[guess] || guess.split(',').map(g2 => g.options[g2]).join('-')
                        return formattedGuess
                    }
                    return [
                        v.code.toLowerCase(), {
                            ...v,
                            inputRegex,
                            extractGuess,
                            isInputValid,
                            formatGuess,
                        }
                    ]
                }))
            }]))
            _window && (_window.localStorage.games = JSON.stringify(games))
            return {
                ...state,
                games: games,
                isLoading: false
            };
    }

    return state;
};

const GameStore = { actionCreators, reducer }
export default GameStore